import React, { useState, useEffect } from 'react';

import Meta from '../components/meta';

import { Link, navigate } from 'gatsby';

import Contents from '../components/contents';



const Thankyou = (props) =>{



    const [sentStatus, setStatus] = useState(false);
    const chkState = props.location.state;

    useEffect(() =>{
        let chkIqd = sessionStorage.getItem('iqd');

        if(!chkIqd){
            navigate('/')
        }    

        if(chkState !== null){
            setStatus(true);
        }else{
            setStatus(false);
            navigate(`/`);
        }
    },[chkState])



    return(
        <>

            {(() => {
                if(sentStatus === true){

                    sessionStorage.removeItem('iqd')

                    return(
                        <>
                            <Meta
                                title="ありがとうございます"
                                description="お問い合わせありがとうございました"
                            />

                            <Contents>

                                <div className="p-404 is-thankyou">
                                    <div className="p-404-box">

                                        <p className="p-404-box--title u-josef">
                                        THANK YOU
                                        </p>
                                        <p className="p-404-box--text">
                                        お問い合わせいただきありがとうございます。<br />
                                        内容を確認させていただき、返信いたしますので今少しお待ちください。
                                        </p>

                                        <div className="c-link">
                                            <Link to="/" title="ホーム" className="c-link--btn">
                                            HOME<span className="c-link--cursor"></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </Contents>

                        </>
                    )
                }
            })()}

        </>
    )
}

export default Thankyou;


